import { FC } from 'react';

import { cloudinaryLoader } from 'components/common/CloudinaryImage';

import { MobileTopSection } from './MobileTopSection';

export const HeroSectionMobileTestIds = {
  container: 'hero-section-mobile-container'
};

export type HeroSectionMobileProps = {
  imageUrl?: string;
  sellerLogoDark?: string;
  caption?: string;
  title?: string;
};

const imageHeight = '450px';

export const HeroSectionMobile: FC<HeroSectionMobileProps> = ({ imageUrl, sellerLogoDark, caption, title }) => (
  <div data-testid={HeroSectionMobileTestIds.container} className='position-relative w-100'>
    <div className='px-4'>
      <MobileTopSection sellerLogoDark={sellerLogoDark} title={title} />
    </div>
    <div
      className='position-absolute w-100'
      style={{
        height: imageHeight,
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 100%)`
      }}
    />
    <div
      style={{
        backgroundImage: `url(${cloudinaryLoader(
          { width: 700, src: imageUrl || 'model_placeholder.png', quality: 100 },
          ['dpr_2.0']
        )})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: imageHeight,
        backgroundSize: 'cover',
        zIndex: 5,
        top: '58px'
      }}
    >
      <div
        className='position-relative p-4 d-flex flex-column justify-content-end align-items-start w-100'
        style={{ height: imageHeight }}
      >
        <h1 className='fs-12px text-white opacity-60 line-height-20px mb-3 text-uppercase'>{title}</h1>
        <div className='text-white fs-24px line-height-32px mb-4' style={{ maxWidth: '300px' }}>
          {caption || 'Shop our selection of luxury watches'}
        </div>
      </div>
    </div>
  </div>
);
