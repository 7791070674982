import { FC } from 'react';
import classNames from 'classnames';

import { useViewport } from 'context/viewport';
import { ShippingAddress, StyledComponent } from 'types';

export const ContactSectionTestIds = {
  container: 'contact-section-container'
};

type SectionProps = {
  text: string | React.ReactNode;
  title: string;
  isMobile: boolean;
} & StyledComponent;

const Section: FC<SectionProps> = ({ text, title, isMobile, style }) => (
  <div
    className={classNames('me-3', {
      'mb-3': isMobile
    })}
    style={{ maxWidth: isMobile ? '100%' : '40%', ...style }}
  >
    <div
      className={classNames('fs-12px opacity-40', {
        'mb-3': !isMobile
      })}
    >
      {title}
    </div>
    <div className='fs-16px line-height-24px'>{text}</div>
  </div>
);

export type ContactSectionProps = {
  address?: ShippingAddress;
  phoneNumber?: string;
  email?: string;
  workingHours?: string;
};

export const ContactSection: FC<ContactSectionProps> = ({ address, phoneNumber, email, workingHours }) => {
  const { isMobile } = useViewport();

  const structuredAddress = (
    <div className='d-flex flex-column'>
      <div>{address?.line1}</div>
      <div>{address?.line2}</div>
      <div>
        {address?.city}, {address?.state} - {address?.postalCode}
      </div>
    </div>
  );

  const formattedPhoneNumber = phoneNumber
    ? `${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8)}`
    : null;

  return (
    <div data-testid={ContactSectionTestIds.container} className='mt-5 mt-md-6 mb-4 riforma-regular'>
      <div className='fs-16px fs-md-18px line-height-md-28px mb-5 riforma-medium opacity-90'>Contact us</div>
      <div className={classNames({ 'd-flex justify-content-between': !isMobile })}>
        {!!address && !!address.line1 && <Section text={structuredAddress} title='ADDRESS' isMobile={isMobile} />}
        {!!phoneNumber && (
          <Section
            text={
              <a className='text-decoration-underline' href={`tel:${phoneNumber}`}>
                {formattedPhoneNumber}
              </a>
            }
            title='CALL US'
            isMobile={isMobile}
          />
        )}
        {!!email && (
          <Section
            text={
              <a className='text-decoration-underline' href={`mailto:${email}`}>
                {email}
              </a>
            }
            title='EMAIL US'
            isMobile={isMobile}
          />
        )}
        {!!workingHours && (
          <Section text={workingHours} title='HOURS' isMobile={isMobile} style={{ whiteSpace: 'pre-wrap' }} />
        )}
      </div>
    </div>
  );
};
