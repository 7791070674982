import { IconBaseProps } from '@react-icons/all-files/lib';

import { useViewport } from 'context/viewport';
import { SocialLink } from 'types';

import { socialIconMap } from './consts';

export const SocialLinksTestIds = {
  container: 'social-links-container'
};

export type SocialLinksProps = {
  links: SocialLink[];
};

const getSocialHref = (link: string) => {
  if (link.includes('https://')) {
    return link;
  }

  return link.includes('http://') ? link.replace('http://', 'https://') : `https://${link}`;
};

export const SocialLinks: React.FC<SocialLinksProps> = ({ links }) => {
  const { isMobile } = useViewport();

  const socialIconProps: IconBaseProps = {
    fill: '#212529',
    size: isMobile ? 24 : 28
  };

  return (
    <div data-testid={SocialLinksTestIds.container} className='d-flex flex-row align-items-center gap-4 pt-4 pt-md-6'>
      {links.map(({ serviceName, link }) => {
        const socialKey = serviceName.trim().toLowerCase();

        if (!(socialKey in socialIconMap)) {
          return;
        }

        const IconComponent = socialIconMap[socialKey];

        return (
          <a key={serviceName} href={getSocialHref(link)} target='_blank' rel='noreferrer'>
            <IconComponent {...socialIconProps} />
          </a>
        );
      })}
    </div>
  );
};
