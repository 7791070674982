import { FC, useEffect, useState } from 'react';

import { Loading } from 'components/common/Loading';
import { SellersBody } from 'components/sellers/SellersBody';
import { SellersHead } from 'components/sellers/SellersHead';
import { useBranding } from 'context/branding';
import { clientApiGet } from 'lib/api_helper';
import { apiUrls } from 'lib/api_urls';
import { cacheKeys } from 'lib/cache_keys';
import { Listing, QueryParams } from 'types';
import { SellerProfile, SellerSiteMetadata } from 'types/sellerProfile';
import * as Cache from 'utils/redis';

type SellerPageProps = {
  listings?: Listing[];
  sellerProfile?: SellerProfile;
  sellerSiteMetadata?: SellerSiteMetadata | null;
};

const SellerPage: FC<SellerPageProps> = ({ listings, sellerProfile, sellerSiteMetadata: sellerSiteMetadataSlug }) => {
  const { sellerSiteMetadata, setSellerSiteMetadata } = useBranding();

  const [sellerListings, setSellerListings] = useState<Listing[] | undefined>(listings);
  const validSellerProfile = sellerProfile || sellerSiteMetadata?.sellerProfile;

  if (sellerSiteMetadataSlug) {
    setSellerSiteMetadata(sellerSiteMetadataSlug);
  }

  useEffect(() => {
    if (listings || !sellerSiteMetadata?.sellerProfile?.id) {
      return;
    }

    clientApiGet<Listing[]>(
      apiUrls.listings({
        'expand[]': ['model'],
        'sellerProfile[]': sellerSiteMetadata?.sellerProfile?.id,
        activePricingModel: 'FIXED_PRICE',
        active: true,
        pageSize: -1
      })
    ).then(result => {
      const sortedListings = result.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());

      setSellerListings(sortedListings);
    });
  }, [listings, sellerSiteMetadata?.sellerProfile?.id]);

  if (!validSellerProfile) {
    return <Loading />;
  }

  return (
    <>
      <SellersHead sellerSiteMetadata={sellerSiteMetadataSlug} />
      <SellersBody listings={sellerListings} />
    </>
  );
};

export const getServerSideProps = async (context: { query: QueryParams }) => {
  const sellerSlug = context.query.seller_slug as string;

  try {
    const sellerProfiles = await clientApiGet<SellerProfile[]>(
      apiUrls.sellerProfiles({ 'slugs[]': [sellerSlug], 'expand[]': ['sellerWebsiteLogoImage'] })
    );

    if (sellerProfiles.length === 0) {
      return { notFound: true };
    }

    let sellerSiteMetadata: SellerSiteMetadata | null = null;

    const cacheKey = cacheKeys.sellerSiteSlug(sellerSlug);
    const cachedResponse = await Cache.get(cacheKey);

    if (cachedResponse) {
      const sellerSiteMetadataList = JSON.parse(cachedResponse);

      sellerSiteMetadata =
        sellerSiteMetadataList && sellerSiteMetadataList.length > 0 ? sellerSiteMetadataList[0] : null;
    } else {
      const sellerSiteMetadataList = await clientApiGet<SellerSiteMetadata[] | null>(
        apiUrls.sellerMetaData({
          'slugs[]': [sellerSlug],
          'expand[]': ['lightLogoImage', 'darkLogoImage', 'heroImage', 'businessAddress', 'faviconImage']
        })
      );

      if (sellerSiteMetadataList && sellerSiteMetadataList.length > 0) {
        Cache.set(cacheKey, JSON.stringify(sellerSiteMetadataList), 86400);
      }

      sellerSiteMetadata =
        sellerSiteMetadataList && sellerSiteMetadataList.length > 0 ? sellerSiteMetadataList[0] : null;
    }

    const sellerProfile = sellerProfiles[0];
    const sellerId = sellerProfiles[0].id;

    const listings = await clientApiGet<Listing[]>(
      apiUrls.listings({
        'expand[]': ['model'],
        'sellerProfile[]': sellerId,
        active: true,
        activePricingModel: 'FIXED_PRICE',
        pageSize: -1
      })
    );
    const requestedSellerWebsiteEnabled = listings.length !== 0 && sellerProfile.sellerWebsiteEnabled;

    if (!requestedSellerWebsiteEnabled) {
      return { notFound: true };
    }

    // sort the listings so that the most recently updated show first
    const sortedListings = listings.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());

    return { props: { listings: sortedListings, sellerProfile, sellerSiteMetadata } };
  } catch {
    return { props: {} };
  }
};

export default SellerPage;
